import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Container,
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  Box,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { JsonRpc } from "eosjs";
import axios from "axios";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import imghowto1 from "./Images/11.png";
import imghowto2 from "./Images/22.png";
import discord from "./Images/Discord.png";
import fb from "./Images/facebook.png";
import waxBloks from "./Images/wax_bloks.png";
import logo from "./Images/logo.png";
import { border, borderRadius } from "@mui/system";

const rpc = new JsonRpc("https://wax.greymass.com", { fetch });

function App({ ual }) {
  let Loan = "awmain1h2oto";
  const [account, setAccount] = useState();
  const [accountUser, setAccountUser] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [wax, setWax] = useState(1);
  const [WaxDays, setWaxDays] = useState(1);
  const handleChange = (event) => {
    setWax(event.target.value);
    setWaxDays(event.target.value);
  };

  
  const handleChangeDays = (event) => {
    setWaxDays(event.target.value);
  };
  // console.log(wax)

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    // top: "40%",
    marginTop:'300px',
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const breakpoints = {
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  };

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Prompt",
        textTransform: "none",
        textAlign: "center",
        fontSize: 25,
        [createTheme({ breakpoints }).breakpoints.down("sm")]: {
          fontSize: 16, //11px
        },
      },
    },
  });

  const openLoginModal = () => {
    if (!ual.activeUser) {
      ual.showModal();
    }
  };

  useEffect(() => {
    // account && console.log(account);
  }, [account]);

  useEffect(() => {
    getBalanceWaxLoan();
  });

  const getBalanceWaxLoan = async () => {
    try {
      if (account == null) {
        const acc = await rpc.get_account(Loan);
        console.log(acc)
        setAccount(acc);
      }
    } catch (e) {
      console.log(e);
    }
  };

  setInterval(() => {
    getBalanceWaxLoan();
  }, 1000*15);

  const renderLoginButton = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={openLoginModal}
          variant="contained"
          color="success"
          style={{ height: "40px" ,backgroundColor:'#FA8E00'}}
        >
          {/* Connect Wallet */}
          {/* Could Wallet Login */}
          Login
        </Button>
        <br />
      </div>
    );
  };

  const renderLogoutButton = () => {
    if (!!ual.activeUser && !!ual.activeAuthenticator) {
      return (
        <div style={{ textAlign: "center" }}>
          <span>ยินดีต้อนรับ, {accountUser && accountUser.account_name}</span>
          <br />
          <span>
            ยอด Wax ของท่าน: {accountUser && accountUser.core_liquid_balance}
          </span>
          <br />
          <Button
            onClick={() => {
              ual.logout();
              setAccountUser("");
            }}
            variant="contained"
            color="error"
            style={{ height: "40px" }}
          >
            Logout
          </Button>
          <br />
        </div>
      );
    }
  };

  useEffect(() => {
    const run = async () => {
      if (ual.activeUser) {
        try {
          const acc = await rpc.get_account(ual.activeUser.accountName);
          setAccountUser(acc);
        } catch (e) {
          console.error(e);
          // process.exit();
          console.log(JSON.stringify(e));
        }
      }
    };
    run();
  }, [ual.activeUser]);

  const renderLoanBalance = () => {
    if (account) {
      let waxBalance = Math.floor(
        account.core_liquid_balance.replace(" WAX", "")
      );
      if (waxBalance < 0) {
        return (
          <div>
            <p>
              <b>
                ณ ตอนนี้จำนวน WAX คงเหลือไม่เพียงพอ หากท่านทำการโอน wax
                แล้วกรุณาติดต่อมาที่ Facebook
              </b>
            </p>
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: "center" }}>
            <div style={{ marginTop: "10px" }}>
              {loading ? (
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#ff0000",
                  }}
                >
                  กรุณารอ Transaction ID สักครู่
                </span>
              ) : (
                <div style={{ textAlign: "left" }}>
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#06ba2a",
                    }}
                  >
                    วิธีใช้มี 1วิธี :
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "##1976d2",
                    }}
                  >
                    วิธีที่1 กดปุ่ม Login เลือกจำนวน Wax
                    ที่ต้องการเมื่อกดทำรายการแล้วกรุณารอ Transaction ID
                  </span>
                  <br />
                  {/* <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "##1976d2",
                    }}
                  >
                    วิธีที่2 ส่งwax จาก{" "}
                    <a href="https://wallet.wax.io/dashboard" target={"_blank"}>
                      https://wallet.wax.io/dashboard
                    </a>{" "}
                    มาที่ {Loan}{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#ff0000",
                    }}
                  >
                    กรุณาเข้ามาเช็คชื่อกระเป๋าทุกครั้งก่อนส่ง...
                  </span>
                  <span
                    onClick={handleOpen}
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#06ba2a",
                      cursor: "pointer",
                    }}
                  >
                    Click เพื่อดูตัวอย่าง
                  </span> */}
                </div>
              )}
              <br />
              {/* <span style={{ fontSize: "23px", fontWeight: "bold" }}>
                ยอด Wax คงเหลือที่สามารถยืมได้
              </span> */}
            </div>

            {/* <div>
              <span
                style={{
                  width: "150px",
                  textAlign: "center",
                  fontSize: "23px",
                  fontWeight: "bold",
                }}
              >
                {waxBalance} WAX
              </span>
            </div> */}
            <Container
              maxWidth="sm"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              {/* <Card
                sx={{ minWidth: 270 }}
                style={{ boxShadow: "8px 11px 20px #545454" , padding: "15px",borderRadius: "20px"}}
              > */}
                <div style={{textAlign: "center", backgroundColor:"#cbc2f7" ,borderRadius:'15px',padding: "10px",boxShadow: "1px 1px 2px #cbc2f7"}}>
                  <span style={{fontSize:"18px", fontWeight:"bold" }}>Available Pool: {waxBalance} WAX</span>
                </div>
              {/* </Card> */}
            </Container>
            <div sx={{ minWidth: 270 }}>
              <Typography
                style={{
                  // width: "420px",
                  // maxHeight: { xs: 233, md: 167 },
                  textAlign: "left",
                  // fontSize: "48px",
                  // fontWeight: "bold",
                  color:'#352d5d',
                  display: '-webkit-inline-box',
                  borderBottom: 'dashed 2px #352d5d',
                }}
                sx={{
                  width: 420,
                  fontSize: '48px',
                  maxWidth: { xs: 320, md: 600 }, 
                  fontSize: { xs: '36px', md: '48px' }, 
                }}
              >
                Choose Package
              </Typography>
            </div>
            <div sx={{ minWidth: 270 }}>
            {renderLoanWaxBtn()}
            </div>
            
          </div>
        );
      }
    } else {
      return (
        <div>
          <p>
            <span
              style={{ fontSize: "15px", fontWeight: "bold", color: "#06ba2a" }}
            >
              วิธีใช้ : กดปุ่ม Login เลือกจำนวน Wax
              ที่ต้องการเมื่อกดทำรายการแล้วกรุณารอ Transaction ID
            </span>
          </p>
          <p>
            <b>ยอด Wax คงเหลือที่สามารถยืมได้</b>
          </p>
          <p>กำลังโหลดข้อมูล...</p>
        </div>
      );
    }
  };

  const actionLoanCPU = async (amountToSend, Days) => {
    // console.log("CPULoan "+amountToSend+" WAX*"+Days);
    // return "eiei";
    if (ual.activeUser) {
      if (amountToSend > 0.0) {
        const actions = {
          actions: [
            {
              account: "eosio.token",
              name: "transfer",
              data: {
                from: ual.activeUser.accountName,
                to: Loan,
                quantity: parseFloat(amountToSend).toFixed(8) + " WAX",
                // quantity: " 0.00000001 WAX",
                memo: "CPULoan "+amountToSend+" WAX*"+Days,
              },
              authorization: [
                {
                  actor: ual.activeUser.accountName,
                  permission: "active",
                },
              ],
            },
          ],
        };

        try {
          setLoading(true);
          const r = await ual.activeUser.signTransaction(actions, {
            blocksBehind: 5,
            expireSeconds: 300,
            broadcast: true,
            sign: true,
          });
          // // console.log(r);
          setTimeout(() => {
            // alert(`https://wax.bloks.io/transaction/`,setLoading(false));
            alert(
              `https://wax.bloks.io/transaction/${r.transactionId}`,
              setLoading(false)
            );
          }, 5000);
        } catch (e) {
          alert(`error ${e}`, setLoading(false));
        }
      } else {
        alert(`เช่าซีพียูล้มเหลว กรุณาจำนวนที่จะเช่ามากกว่า 0 เท่านั้น`);
      }
    } else {
      alert(`เช่าซีพียูล้มเหลว กรุณาล็อกอินก่อน, ทำรายการ`);
    }
  };

  const renderLoanWaxBtn = () => {
    const listWax =  [
      {
        "id":1,
        "value":100,
        "amount":1*wax,
        "days":1*WaxDays,
      },
      {
        "id":2,
        "value":200,
        "amount":2*wax,
        "days":1*WaxDays,
      },
      {
        "id":3,
        "value":300,
        "amount":3*wax,
        "days":1*WaxDays,
      },
      // {
      //   "id":4,
      //   "value":300*wax,
      //   "amount":15*wax,
      //   "days":15
      // },
    ]
    const listWaxValue =  [
      {
        "value":1,
        "muti":1,
      },
      {
        "value":3,
        "muti":3,
      },
      {
        "value":7,
        "muti":7,
      },
      {
        "value":15,
        "muti":15,
      },
      // {
      //   "value":500,
      //   "muti":5,
      // },
      // {
      //   "value":600,
      //   "muti":6,
      // },
      // {
      //   "value":700,
      //   "muti":7,
      // },
      // {
      //   "value":800,
      //   "muti":8,
      // },
      // {
      //   "value":900,
      //   "muti":9,
      // },
      // {
      //   "value":1000,
      //   "muti":10,
      // },
    ]

    return (
      <div>
        <div>
            {/* <div
              style={{
                display:'block',
                width:'420px',
                height:'77px',
                paddingLeft:'45px',
                fontSize: "25px",
                fontWeight: "bold",
                textAlign: "center",
                color:'#ffffff',
                display: 'block',
                backgroundColor:'#fa8b04',
                margin:'5px',
                borderRadius:"10px"
              }}
            >
              จำนวนที่ต้องการเช่า wax55555
            </div> */}
            <Box
              sx={{
                boxShadow: 2,
                textAlign: "right",
                backgroundColor: "#fa8b04",
                borderColor: "#c5c5c5",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                padding: "10px 10px",
                margin: "5px 0px",
                borderRadius: "10px",
                // width: "400px",
                display: '-webkit-inline-box'
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 400,
                  maxWidth: { xs: 300, md: 600 },
                }}
              >
                <Grid item xs={6} md={6}>
                  <Typography
                    color="#ffffff"
                    style={{fontSize:'18px', fontWeight: "bold" ,Color:'#ffffff',padding:'5px',borderRadius:'10px'}}
                  >
                    จำนวนวันที่ต้องการเช่า
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Days</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={age}
                      label="wax"
                      onChange={handleChange}
                      style={{backgroundColor:"#ffffff"}}
                    >
                      {listWaxValue.map((e, i) => (
                        <MenuItem
                          key={i}
                          value={e.muti}
                        >
                          {e.value}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={i}>100</MenuItem>
                      <MenuItem value={20}>200</MenuItem>
                      <MenuItem value={30}>300</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography
                    color="#ffffff"
                    style={{fontSize:'18px', fontWeight: "bold" ,Color:'#ffffff',padding:'5px',borderRadius:'10px'}}
                  >
                    {/* Wax */}
                  </Typography>
                </Grid>
              </Grid>

            </Box>

          <div>
            {listWax.map((e, i) => {
              return (
                <Box
                  sx={{
                    boxShadow: 2,
                    textAlign: "right",
                    backgroundColor: "#cbc2f7",
                    borderColor: "#c5c5c5",
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    padding: "10px 10px",
                    margin: "5px 0px",
                    borderRadius: "10px",
                    // width:'400px',
                    display: '-webkit-inline-box'
                  }}
                  key={i}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: 400,
                      maxWidth: { xs: 300, md: 600 },
                    }}
                  >
                    <Grid item xs={3} md={3}>
                      <Typography
                        color="#ffffff"
                        style={{ fontWeight: "bold" ,backgroundColor:'#fa8b04',padding:'5px',borderRadius:'10px'}}
                      >
                        {e.days} Days
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography
                        color="#362d64"
                        style={{ fontWeight: "bold" ,paddingLeft:'5px'}}
                      >
                        {e.value} Wax
                      </Typography>
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <Button
                        onClick={() => actionLoanCPU(e.amount , e.days)}
                        variant="contained"
                        component="span"
                        sx={{
                          width: 140,
                          maxWidth: { xs: 120, md: 600 },
                        }}
                        style={{ textAlign: "center" ,backgroundColor:'#073a4b',borderRadius:'30px'}}
                      >
                        <Typography
                          color="#FFFFFF"
                          sx={{
                            fontSize: 15,
                            fontSize: { xs: '15px', md: '15px' }, 
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Buy Now
                          <br></br>
                          {e.amount} WAX
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const ModalHowto = () => {
    
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 300 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img src={imghowto1} width="250" />
            <Typography
              color="#1976d2"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              ใส่จำนวน wax ถ้าใส่ 2wax จะ stack กลับไป 100wax
            </Typography>
            <img src={imghowto2} width="250" />
            <Typography
              color="#1976d2"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              ตรง memo ให้เอาจำนวนwaxที่ใส่ก่อนหน้านี้มาใส่ให้ตรงกันเช่น
              "CPULoan 2 WAX" ไม่งั้นระบบจะไม่ stack กลับไป
            </Typography>
          </Grid>
          <Grid textAlign="right">
            <span onClick={handleClose} style={{ fontSize: "14px", fontWeight: "bold", cursor:"pointer" }}>
              Close
            </span>
          </Grid>
        </Box>
      </Modal>
    );
  };

  return (
    
    <ThemeProvider theme={theme}>
      <div style={{textAlign: "center"}}>
        <img src={logo} style={{width:"120px"}}/>
      </div>
      <div style={{textAlign: "center"}}>
        <span style={{fontSize:"48px",fontWeight:"bold",color:"#FFFFFF"}}>WAX STACK CPU</span>
      </div>
      {/* <Container
        maxWidth="sm"
        style={{ marginTop: "15px", marginBottom: "15px"}}
      >
        <Card
          sx={{ minWidth: 270 }}
          style={{ boxShadow: "8px 11px 20px #545454" , padding: "10px",borderRadius: "20px"}}
        >
          <div style={{textAlign: "center" }}>
            <span style={{fontSize:"18px", fontWeight:"bold"}}>ให้พี่ใส่ Contact</span>
          </div>
        </Card>
      </Container> */}
      <Container
        maxWidth="sm"
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <Card
          sx={{ minWidth: 270 }}
          style={{ boxShadow: "8px 11px 20px #545454" , padding: "15px",borderRadius: "20px"}}
        >
          <Typography
            variant="h5"
            component="div"
            style={{ textAlign: "center" }}
          >
            {ual.activeUser ? renderLogoutButton() : renderLoginButton()}
          </Typography>
        </Card>
      </Container>

      {/* <Container
        maxWidth="sm"
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <Card
          sx={{ minWidth: 270 }}
          style={{ boxShadow: "8px 11px 20px #545454" , padding: "15px",borderRadius: "20px"}}
        >
          <div style={{textAlign: "center", backgroundColor:"#cbc2f7" ,borderRadius:'15px',padding: "10px",boxShadow: "1px 1px 2px #cbc2f7"}}>
            <span style={{fontSize:"18px", fontWeight:"bold" }}>Available Pool: ... WAX</span>
          </div>
        </Card>
      </Container> */}

      <Container
        maxWidth="sm"
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <Card
          sx={{ minWidth: 270 }}
          style={{ boxShadow: "8px 11px 20px #545454" ,borderRadius: "20px"}}
        >
          <CardContent>
            <Typography color="text.secondary" gutterBottom>
              <span style={{ color: "rgb(25, 118, 210)", fontSize: 30 }}>
                24-hour CPU loan service
              </span>
            </Typography>
            {renderLoanBalance()}
          </CardContent>
          <CardActions></CardActions>
        </Card>
        <ModalHowto />
      </Container>
      <div style={{textAlign: "center" }}>
        {/* <Button
          // onClick={() => }
          variant="contained"
          component="span"
          style={{ width: "240px", textAlign: "center" ,backgroundColor:'#fa8b04',borderRadius:'10px'}}
        >
          <Typography
            color="#ffffff"
            style={{ fontWeight: "bold"}}
          >
            Contact US
          </Typography>
        </Button> */}
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
          <Typography
            color="#ffffff"
            style={{ fontWeight: "bold", fontSize:"17.22px", marginRight:"5px"}}
          >
            Waxpcpu Community
          </Typography>
          <a href="https://discord.gg/jsqFJ5yPXe" target={"_blank"}>
            <img src={discord} width="40" />
          </a>{" "}
          <a href="https://wallet.wax.io/dashboard" target={"_blank"}>
            <img src={waxBloks} width="50" />
          </a>
        </Grid>
        
      </div>
    </ThemeProvider>
  );
}

export default App;
